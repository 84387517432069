import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction';

const OrderPayment = () => {
    const dispatch = useDispatch();

    // Get the status parameter from the route
    const { status } = useParams();

    // Use the status parameter in your component logic
    const message = status === 'success' ? 'Payment successful!' : 'Payment failed. Please try again.';

    if (status === 'success') {
        //--clear customer cart
        dispatch(rootAction.cartAction.setCustomerCart('[]'));
        dispatch(rootAction.cartAction.SetTotalCartItems(0));
        localStorage.setItem("cartItems", "[]");
    }

    return (
        <div className="container w-100">
            <div className="m-auto my-5 text-center">
                <h1>{status === 'success' ? 'Success!' : 'Failed!'}</h1>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default OrderPayment;