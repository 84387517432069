import React from "react";
import { Row, Col, Container, Media } from "reactstrap";
import Slider from "react-slick";

var settings = {
    autoplay: true,
    autoplaySpeed: 10000,
};

const Review = [
    {
        img: "/images/testimonial/1.jpg",
        user: "Fatih Birol",
        review:
            "Renewable energy is no longer a niche fuel.",
    },
    {
        img: "/images/testimonial/2.jpg",
        user: "Gloria Reuben",
        review:
            "A transition to clean energy is about making an investment in our future.",
    },
    {
        img: "/images/testimonial/3.jpg",
        user: "Margot Wallstrom",
        review:
            "There can be no sustainable development without sustainable energy development."
    },
];
const CustomerTestimonial = () => {
    return (
        <>
            <section className="testimonial testimonial-inverse">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="slide-1 no-arrow">
                                <Slider {...settings}>
                                    {Review.map((data, i) => (
                                        <div key={i}>
                                            <div className="testimonial-contain">
                                                <div className="media text-center">
                                                    {/*<div className="testimonial-img">*/}
                                                    {/*    */}{/*  <Media src={myImage} className="img-fluid rounded-circle" alt="testimonial" />*/}
                                                    {/*</div>*/}
                                                    <div className="media-body">
                                                        <h3 className="pb-2">{data.review}</h3>
                                                        <p className="font-italic font-weight-light"> {data.user}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default CustomerTestimonial;
