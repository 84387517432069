import React, { useState } from 'react';
import { Row, Col, Container, Media } from "reactstrap";
import myImage from '../../../resources/custom/images/call_img.png';
import { Link } from 'react-router-dom';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';

const ContactBanner = () => {
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    return (
        <>
            <section className="contact-banner">
                <Container>
                    <Row>
                        <Col>
                            <div className="contact-banner-contain">
                                <div className="contact-banner-img">
                                    <Media src={myImage} className="img-fluid" alt="call-banner" />
                                </div>
                                <div>
                                    <h3>if you have any question please 
                                        <Link to={`/${getLanguageCodeFromSession()}/contact-us`} style={{ textDecoration: 'underline' }} className="font-bold text-white h3">
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Contact Us", "lbl_footr_about")
                                            :
                                            " Contact Us"
                                        }
                                    </Link> </h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ContactBanner;
