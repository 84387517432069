import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import CustomerTestimonial from '../../components/shared/CustomerTestimonial';

const About = () => {
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

    return (
        <>


            <Helmet>
                <title>{siteTitle} - About Us</title>
                <meta name="description" content={siteTitle + " - About us page"} />
                <meta name="keywords" content="About us"></meta>
            </Helmet>

            <SiteBreadcrumb title="About-Us" parent="Home" />

            <section className="about-page section-big-py-space">
                <div className="custom-container">
                    <Row>
                        <Col lg="6">
                            <div className="banner-section">
                                <Media src={aboutImg} className="img-fluid w-100" alt="" />
                            </div>
                        </Col>
                        <Col lg="6">
                            <h4>About our Store</h4>
                            <p className="mb-2">
                                {" "}
                                Welcome to <b>Pamodzi Green Online Store</b>
                            </p>
                            <p className="mb-2">
                                {" "}
                                Pamodzi Green Energy (Pty) Ltd, is located in Johannesburg, Gauteng
                                Province, South Africa. Pamodzi Green Energy is a service provider focusing
                                on providing the world's most advanced intelligent photovoltaic tracking
                                bracket system solutions and intelligent manufacturing. This is a technologybased enterprise serving global clean energy, committed to promoting
                                energy reform and continuously optimizing energy infrastructure and
                                materials. application to build a better future for mankind.

                            </p>
                            <p className="mb-2">
                                {" "}

                                Pamodzi Energy (Pty) Ltd. is an exclusive distributor of high-tech electrical equipments, which feature
                                fire-resistant cables with high performance, high heat resistance, high life and other special cables.
                                With excellent equipment and abundant product structure, the Group took the lead in forming a product
                                layout featuring special cables as the fulcrum, full coverage of conventional cables and full series of
                                fire-proof cables.
                            </p>
                        </Col>
                    </Row>
                </div>
            </section>

            <CustomerTestimonial />

        </>
    );

}

export default About;
